import store from '@/store'
import Inbox from '@/views/Inbox/Inbox.vue'
import PageNotFound from '@/views/PageNotFound/PageNotFound.vue'
import Vue from 'vue'
import VueRouter from 'vue-router'
import authRoutes from './auth/auth-routes'
import customerRoutes from './customers/customer-routes'
import dashboardRoutes from './dashboard/dashboard-routes'
import merchantAccountRoutes from './merchantAccounts/merchant-account-routes'
import merchantLocationRoutes from './merchantLocations/merchant-location-routes'
import merchantRoutes from './merchants/merchant-routes'
import messageTemplateRoutes from './messageTemplates/message-template-routes'
import ownerRoutes from './owners/owner-routes'
import onboardingRoutes from './summary/onboarding-routes'
import dataAdminRoutes from './dataAdmin/data-admin-routes'
import dashboardHomeRoutes from './customerSuccessDashboard/customer-success-dashboard-routes'

Vue.use(VueRouter)

const routes = [
  ...dashboardRoutes,
  ...customerRoutes,
  ...merchantRoutes,
  ...ownerRoutes,
  ...merchantLocationRoutes,
  ...merchantAccountRoutes,
  ...onboardingRoutes,
  ...messageTemplateRoutes,
  ...authRoutes,
  ...dataAdminRoutes,
  ...dashboardHomeRoutes,
  {
    path: '/merchants/:merchantID?/inbox',
    component: Inbox,
    name: 'Inbox'
  },
  {
    path: '*',
    component: PageNotFound,
    name: '404',
    meta: { publicRoute: true }
  }
]

const router = new VueRouter({
  routes
})

router.beforeEach((to, from, next) => {
  if (to.meta.publicRoute) {
    // If route is public contine
    next()
  } else if (store.getters.isAuthenticated) {
    // If route is not public, but we are authenticated, continue.
    // Make sure the heartbeat is running, if so.
    store.dispatch('heartbeat')
    next()
  } else {
    // Otherwise redirect to login
    next({ name: 'Login', query: { next: to.fullPath } })
  }
})

router.beforeEach((to, from, next) => {
  // Role checker for custom permissions
  if (to.meta?.require_custom_roles) {
    if (
      store?.getters.currentMerchant?.check_custom_roles &&
      !to.meta?.require_custom_roles?.some(r =>
        store.getters.accessClaims?.roles.includes(r)
      )
    ) {
      if (to.meta?.role_redirect) return next({ name: to.meta?.role_redirect })
      return next({ name: 'Home' })
    }
  }
  next()
})

router.beforeEach((to, from, next) => {
  // Role checker for permissions
  if (to.meta?.require_roles) {
    if (
      !to.meta?.require_roles?.some(r =>
        store.getters.accessClaims?.roles.includes(r)
      )
    ) {
      if (to.meta?.role_redirect) return next({ name: to.meta?.role_redirect })
      return next({ name: 'Home' })
    }
  }
  next()
})

router.beforeEach((to, from, next) => {
  // Redirect legacy users to legacy report
  if (to.name === 'TransactionReport' && store.getters.isLegacyUser) {
    next({ name: 'TransactionReportLegacy' })
  }
  next()
})

router.beforeEach((to, from, next) => {
  const merchant = store?.getters.currentMerchant
  if (merchant && merchant?.is_integrated && to.meta?.disallowIntegrated) {
    return next({ name: 'Home' })
  }
  next()
})

export default router
