export default {
  resetPassword: () => `/api/password-reset/`,
  listProfiles: () => `/api/profile/`,
  retrieveCustomerRoot: id => `/api/customers/${id}/`,
  createCustomerInvite: () => `/api/customer_invites/`,
  listMerchantOrganizations: () => `/api/merchants/`,
  createMerchantOrganization: () => `/api/merchants/`,
  retrieveMerchantOrganization: id => `/api/merchants/${id}/`,
  updateMerchantOrganization: id => `/api/merchants/${id}/`,
  partialUpdateMerchantOrganization: id => `/api/merchants/${id}/`,
  listPropayAccountNumbers: () => `/api/iqr/`,
  dryRunInterchangeQualificationReport: () =>
    `/api/iqr/dry_run_iqr/dry_run_iqr/`,
  listBlytzpayInvoices: () => `/api/bpinvoices/`,
  retrieveBlytzpayInvoice: id => `/api/bpinvoices/${id}`,
  listBlytzpayInvoiceItems: id => `/api/bpinvoices/${id}/invoice_items/`,
  connectIntegratedPartner: id =>
    `/api/merchants/${id}/connect_external_system/`,
  createMerchantSettings: id => `/api/merchants/${id}/settings/`,
  retrieveMerchantSettings: id => `/api/merchants/${id}/settings/`,
  importCustomer: id => `/api/merchants/${id}/import_customer/`,
  uploadLogo: id => `api/merchants/${id}/upload_logo/`,
  listCustomers: merchant_org_id =>
    `/api/merchants/${merchant_org_id}/customers/`,
  exportCustomerList: merchant_org_id =>
    `/api/merchants/${merchant_org_id}/customer-accounts/export_customers/`,
  listMerchantCustomerAccounts: merchant_org_id =>
    `/api/merchants/${merchant_org_id}/customer-accounts/`,
  createCustomer: merchant_org_id =>
    `/api/merchants/${merchant_org_id}/customers/`,
  retrieveCustomer: (merchant_org_id, id) =>
    `/api/merchants/${merchant_org_id}/customers/${id}/`,
  updateCustomer: (merchant_org_id, id) =>
    `/api/merchants/${merchant_org_id}/customers/${id}/`,
  partialUpdateCustomer: (merchant_org_id, id) =>
    `/api/merchants/${merchant_org_id}/customers/${id}/`,
  upload: merchant_org_id =>
    `/api/merchants/${merchant_org_id}/customers/upload/`,
  updateLastViewed: (merchant_org_id, id) =>
    `/api/merchants/${merchant_org_id}/customers/${id}/update_last_viewed/`,
  createPaymentMethod: (merchant_org_id, id) =>
    `/api/merchants/${merchant_org_id}/customers/${id}/create_payment_method/`,
  destroyPaymentMethod: (merchant_org_id, customer_id) =>
    `/api/merchants/${merchant_org_id}/customers/${customer_id}/delete_payment_method/`,
  retrieveAutopayAccounts: (merchant_org_id, customer_id) =>
    `/api/merchants/${merchant_org_id}/customers/${customer_id}/payment_method_accounts/`,
  retrievePaymentMethodPromises: (merchant_org_id, id) =>
    `/api/merchants/${merchant_org_id}/customers/${id}/payment_method_promises/`,
  retrieveScheduledPayments: (merchant_org_id, id) =>
    `/api/merchants/${merchant_org_id}/customers/${id}/scheduled_payments/`,
  retrieveCustomerPaymentMethods: (merchant_org_id, id) =>
    `/api/merchants/${merchant_org_id}/customers/${id}/payment_methods/`,
  enabledPaymentMethods: (merchant_org_id, id) =>
    `/api/merchants/${merchant_org_id}/customers/${id}/enabled_payment_methods/`,
  posPayment: merchant_org_id =>
    `/api/merchants/${merchant_org_id}/pos-payment/`,
  listMerchantAccounts: merchant_org_id =>
    `/api/merchants/${merchant_org_id}/merchant_accounts/`,
  createMerchantAccount: merchant_org_id =>
    `/api/merchants/${merchant_org_id}/merchant_accounts/`,
  retrieveMerchantAccount: (merchant_org_id, id) =>
    `/api/merchants/${merchant_org_id}/merchant_accounts/${id}/`,
  updateMerchantAccount: (merchant_org_id, id) =>
    `/api/merchants/${merchant_org_id}/merchant_accounts/${id}/`,
  partialUpdateMerchantAccount: (merchant_org_id, id) =>
    `/api/merchants/${merchant_org_id}/merchant_accounts/${id}/`,
  destroyMerchantAccount: (merchant_org_id, id) =>
    `/api/merchants/${merchant_org_id}/merchant_accounts/${id}/`,
  listMerchantLocations: merchant_org_id =>
    `/api/merchants/${merchant_org_id}/merchant_locations/`,
  createMerchantLocation: merchant_org_id =>
    `/api/merchants/${merchant_org_id}/merchant_locations/`,
  retrieveMerchantLocation: (merchant_org_id, id) =>
    `/api/merchants/${merchant_org_id}/merchant_locations/${id}/`,
  updateMerchantLocation: (merchant_org_id, id) =>
    `/api/merchants/${merchant_org_id}/merchant_locations/${id}/`,
  partialUpdateMerchantLocation: (merchant_org_id, id) =>
    `/api/merchants/${merchant_org_id}/merchant_locations/${id}/`,
  destroyMerchantLocation: (merchant_org_id, id) =>
    `/api/merchants/${merchant_org_id}/merchant_locations/${id}/`,
  listAccounts: merchant_account_id =>
    `/api/merchants/${merchant_account_id}/accounts/`,
  createAccount: merchant_account_id =>
    `/api/merchants/${merchant_account_id}/accounts/`,
  updateAccount: (merchant_account_id, id) =>
    `/api/merchants/${merchant_account_id}/accounts/${id}/`,
  partialUpdateAccount: (merchant_account_id, id) =>
    `/api/merchants/${merchant_account_id}/accounts/${id}/`,
  destroyAccount: (merchant_account_id, id) =>
    `/api/merchants/${merchant_account_id}/accounts/${id}/`,
  changeAutopay: (merchant_account_id, id) =>
    `/api/merchants/${merchant_account_id}/accounts/${id}/change_autopay/`,
  calculateFee: (merchant_account_id, id) =>
    `/api/merchants/${merchant_account_id}/accounts/${id}/calculate_fee/`,
  createPromises: (merchant_account_id, id) =>
    `/api/merchants/${merchant_account_id}/accounts/${id}/setup_promises/`,
  voidPromises: (merchant_account_id, id) =>
    `/api/merchants/${merchant_account_id}/accounts/${id}/void_promises/`,
  getAccountPromises: (merchant_account_id, id) =>
    `/api/merchants/${merchant_account_id}/accounts/${id}/promises/`,
  createAccountPaymentLink: (merchant_org_id, id) =>
    `/api/merchants/${merchant_org_id}/accounts/${id}/create_payment_link/`,
  listAccountCustomers: (merchant_org_id, id) =>
    `/api/merchants/${merchant_org_id}/accounts/${id}/list_account_customers/`,
  listBusinessOwners: merchant_org_id =>
    `/api/merchants/${merchant_org_id}/business_owners/`,
  createBusinessOwner: merchant_org_id =>
    `/api/merchants/${merchant_org_id}/business_owners/`,
  retrieveBusinessOwner: (merchant_org_id, id) =>
    `/api/merchants/${merchant_org_id}/business_owners/${id}/`,
  listMessageTemplates: merchant_org_id =>
    `/api/merchants/${merchant_org_id}/message_templates/`,
  createMessageTemplate: merchant_org_id =>
    `/api/merchants/${merchant_org_id}/message_templates/`,
  retrieveMessageTemplate: (merchant_org_id, id) =>
    `/api/merchants/${merchant_org_id}/message_templates/${id}/`,
  randomRenderMessageTemplate: (merchant_org_id, id) =>
    `/api/merchants/${merchant_org_id}/message_templates/${id}/random_render/`,
  renderMessageTemplate: (merchant_org_id, id) =>
    `/api/merchants/${merchant_org_id}/message_templates/${id}/render/`,
  updateMessageTemplate: (merchant_org_id, id) =>
    `/api/merchants/${merchant_org_id}/message_templates/${id}/`,
  partialUpdateMessageTemplate: (merchant_org_id, id) =>
    `/api/merchants/${merchant_org_id}/message_templates/${id}/`,
  destroyMerchantOrganization: id => `/api/merchants/${id}/`,

  listMerchantAccountLocations: (
    parent_lookup_merchant__id,
    parent_lookup_merchant_location_id
  ) =>
    `/api/merchants/${parent_lookup_merchant__id}/merchant_locations/${parent_lookup_merchant_location_id}/merchant_accounts`,

  listMerchantContracts: parent_lookup_merchant__id =>
    `/api/merchants/${parent_lookup_merchant__id}/merchant_contracts/`,
  createMerchantContract: parent_lookup_merchant__id =>
    `/api/merchants/${parent_lookup_merchant__id}/merchant_contracts/`,
  retrieveMerchantContract: (parent_lookup_merchant__id, id) =>
    `/api/merchants/${parent_lookup_merchant__id}/merchant_contracts/${id}/`,
  updateMerchantContract: (parent_lookup_merchant__id, id) =>
    `/api/merchants/${parent_lookup_merchant__id}/merchant_contracts/${id}/`,
  partialUpdateMerchantContract: (parent_lookup_merchant__id, id) =>
    `/api/merchants/${parent_lookup_merchant__id}/merchant_contracts/${id}/`,
  destroyMerchantContract: (parent_lookup_merchant__id, id) =>
    `/api/merchants/${parent_lookup_merchant__id}/merchant_contracts/${id}/`,
  listMerchantPricingTiers: parent_lookup_contract__merchant__id =>
    `/api/merchants/${parent_lookup_contract__merchant__id}/merchant_pricing_tiers/`,
  createMerchantPricingTier: parent_lookup_contract__merchant__id =>
    `/api/merchants/${parent_lookup_contract__merchant__id}/merchant_pricing_tiers/`,
  retrieveMerchantPricingTier: (parent_lookup_contract__merchant__id, id) =>
    `/api/merchants/${parent_lookup_contract__merchant__id}/merchant_pricing_tiers/${id}/`,
  updateMerchantPricingTier: (parent_lookup_contract__merchant__id, id) =>
    `/api/merchants/${parent_lookup_contract__merchant__id}/merchant_pricing_tiers/${id}/`,
  partialUpdateMerchantPricingTier: (
    parent_lookup_contract__merchant__id,
    id
  ) =>
    `/api/merchants/${parent_lookup_contract__merchant__id}/merchant_pricing_tiers/${id}/`,
  destroyMerchantPricingTier: (parent_lookup_contract__merchant__id, id) =>
    `/api/merchants/${parent_lookup_contract__merchant__id}/merchant_pricing_tiers/${id}/`,
  listMerchantPaymentMethods: merchant_org_id =>
    `/api/merchants/${merchant_org_id}/payment-methods/`,
  partialUpdatePaymentMethods: (merchant_org_id, id) =>
    `/api/merchants/${merchant_org_id}/payment-methods/${id}/`,
  listEmailReportings: (
    parent_lookup_merchant_location__merchant__id,
    location_id
  ) =>
    `/api/merchants/${parent_lookup_merchant_location__merchant__id}/merchant_locations/${location_id}/email_reporting/`,
  createEmailReporting: (
    parent_lookup_merchant_location__merchant__id,
    location_id
  ) =>
    `/api/merchants/${parent_lookup_merchant_location__merchant__id}/merchant_locations/${location_id}/email_reporting/`,
  retrieveEmailReporting: (
    parent_lookup_merchant_location__merchant__id,
    location_id,
    id
  ) =>
    `/api/merchants/${parent_lookup_merchant_location__merchant__id}/merchant_locations/${location_id}/email_reporting/${id}/`,
  updateEmailReporting: (
    parent_lookup_merchant_location__merchant__id,
    location_id,
    id
  ) =>
    `/api/merchants/${parent_lookup_merchant_location__merchant__id}/merchant_locations/${location_id}/email_reporting/${id}/`,
  partialUpdateEmailReporting: (
    parent_lookup_merchant_location__merchant__id,
    location_id,
    id
  ) =>
    `/api/merchants/${parent_lookup_merchant_location__merchant__id}/merchant_locations/${location_id}/email_reporting/${id}/`,
  destroyEmailReporting: (
    parent_lookup_merchant_location__merchant__id,
    location_id,
    id
  ) =>
    `/api/merchants/${parent_lookup_merchant_location__merchant__id}/merchant_locations/${location_id}/email_reporting/${id}/`,

  retrieveAccount: (parent_lookup_merchant_account__merchant__id, id) =>
    `/api/merchants/${parent_lookup_merchant_account__merchant__id}/accounts/${id}/`,

  updateBusinessOwner: (parent_lookup_merchant__id, id) =>
    `/api/merchants/${parent_lookup_merchant__id}/business_owners/${id}/`,
  partialUpdateBusinessOwner: (parent_lookup_merchant__id, id) =>
    `/api/merchants/${parent_lookup_merchant__id}/business_owners/${id}/`,
  destroyBusinessOwner: (parent_lookup_merchant__id, id) =>
    `/api/merchants/${parent_lookup_merchant__id}/business_owners/${id}/`,

  destroyMessageTemplate: (parent_lookup_merchant__id, id) =>
    `/api/merchants/${parent_lookup_merchant__id}/message_templates/${id}/`,
  listHeartbeats: () => `/`,
  listtransaction_report_urls: () => `/api/reports/`,
  listInvites: () => `/api/partner-invite/`,
  createInvite: () => `/api/partner-invite/`,
  sendMessagesMerchantOrganization: id => `/api/merchants/${id}/send_messages/`,
  submitToProcessorMerchantAccount: (merchant_id, merchant_account_id) =>
    `/api/merchants/${merchant_id}/merchant_accounts/${merchant_account_id}/submit_to_processor/`,
  sendPaymentRequest: (merchant_account_id, id) =>
    `/api/merchants/${merchant_account_id}/accounts/${id}/send_payment_request/`,
  sendInviteRequest: (merchant_account_id, id) =>
    `/api/merchants/${merchant_account_id}/accounts/${id}/send_invite_request/`,
  sendPromiseRequest: (merchant_account_id, id) =>
    `/api/merchants/${merchant_account_id}/accounts/${id}/send_promise_request/`,
  createPayment: merchant_org_id =>
    `/api/merchants/${merchant_org_id}/payments/`,
  retrievePayment: (merchant_org_id, id) =>
    `/api/merchants/${merchant_org_id}/payments/${id}/`,
  createRequestedPayment: merchant_org_id =>
    `/api/merchants/${merchant_org_id}/requested_payment/`,
  sendPaymentReceipt: (merchant_org_id, id) =>
    `/api/merchants/${merchant_org_id}/payments/${id}/send_receipt/`,
  downloadPaymentReceipt: (merchant_org_id, id) =>
    `/api/merchants/${merchant_org_id}/payments/${id}/download_receipt/`,
  updatePaymentStatus: (merchant_org_id, id) =>
    `/api/merchants/${merchant_org_id}/payments/${id}/update_payment_status/`,
  sendMessage: (merchant_account_id, id) =>
    `/api/merchants/${merchant_account_id}/accounts/${id}/send_message/`,
  uploadMMSMedia: (merchant_account_id, id) =>
    `/api/merchants/${merchant_account_id}/accounts/${id}/upload_mms_media/`,
  createBlytzTokenObtainPair: () => `/api/token/`,
  createTokenRefresh: () => `/api/token/refresh/`,
  blacklistToken: () => `api/token/blacklist/`,
  updateCustomerOptins: (merchant_org_id, customer_id, id) =>
    `/api/merchants/${merchant_org_id}/customers/${customer_id}/optin/${id}/`,
  listCustomerMessages: (merchant_org_id, id) =>
    `/api/merchants/${merchant_org_id}/customers/${id}/messages/`,
  listMessageBatches: merchant_org_id =>
    `/api/merchants/${merchant_org_id}/message_batches/`,
  retrieveMessageBatchDetails: (merchant_org_id, id) =>
    `/api/merchants/${merchant_org_id}/message_batches/${id}/`,
  listCustomerAccountTransactions: (merchant_id, customer_id, account_id) =>
    `/api/merchants/${merchant_id}/customers/${customer_id}/accounts/${account_id}/transactions/`,
  listDashboardFailedPayments: merchant_org_id =>
    `/api/merchants/${merchant_org_id}/dashboard_failed_payments/`,
  listInvoices: merchant_org_id =>
    `/api/merchants/${merchant_org_id}/invoices/`,
  retrieveInvoice: (merchant_org_id, id) =>
    `/api/merchants/${merchant_org_id}/invoices/${id}/`,
  listUnreadCustomerMessages: merchant_org_id =>
    `/api/merchants/${merchant_org_id}/unread_messages/`,
  listAccountNotes: (merchant_id, account_id) =>
    `/api/merchants/${merchant_id}/accounts/${account_id}/notes/`,
  createAccountNotes: (merchant_id, account_id) =>
    `/api/merchants/${merchant_id}/accounts/${account_id}/notes/`,
  accountNoteDetail: (merchant_id, account_id, note_id) =>
    `/api/merchants/${merchant_id}/accounts/${account_id}/notes/${note_id}`,
  listUserInvites: merchant_org_id =>
    `/api/merchants/${merchant_org_id}/invites/`,
  userInvitesDetail: (merchant_org_id, inviteID) =>
    `/api/merchants/${merchant_org_id}/invites/${inviteID}/`,
  listUsers: merchant_org_id => `/api/merchants/${merchant_org_id}/users/`,
  userDetail: (merchant_org_id, userID) =>
    `/api/merchants/${merchant_org_id}/users/${userID}/`,
  userDisable: (merchant_org_id, userID) =>
    `/api/merchants/${merchant_org_id}/users/${userID}/disable/`,
  clearEvents: () => `/api/clear-events/`,
  listCustomerGroups: merchant_org_id =>
    `/api/merchants/${merchant_org_id}/customer-groups/`,
  createCustomerGroup: merchant_org_id =>
    `/api/merchants/${merchant_org_id}/customer-groups/`,
  updateCustomerGroup: (merchant_org_id, id) =>
    `/api/merchants/${merchant_org_id}/customer-groups/${id}/`,
  sendCustomersMessage: merchant_org_id =>
    `/api/merchants/${merchant_org_id}/send_message/`,
  retrieveCustomerOptouts: merchant_org_id =>
    `/api/merchants/${merchant_org_id}/opted_out/`,
  listMappings: merchant_org_id =>
    `/api/merchants/${merchant_org_id}/mappings/`,
  createMappings: merchant_org_id =>
    `/api/merchants/${merchant_org_id}/mappings/`,
  partialUpdateMappings: (merchant_org_id, id) =>
    `/api/merchants/${merchant_org_id}/mappings/${id}/`,
  listAccountTags: merchant_org_id =>
    `/api/merchants/${merchant_org_id}/account-tags/`,
  accountTagDetail: (merchant_org_id, tag_id) =>
    `/api/merchants/${merchant_org_id}/account-tags/${tag_id}/`,
  billingSummary: id =>
    `/api/merchants/${id}/merchant_reports/billing_summary/`,
  newAccounts: id => `/api/merchants/${id}/merchant_reports/new_accounts/`,
  registeredCustomers: id =>
    `/api/merchants/${id}/merchant_reports/registered_customers/`,
  activeCustomers: id =>
    `/api/merchants/${id}/merchant_reports/active_customers/`,
  paymentOrigins: id =>
    `/api/merchants/${id}/merchant_reports/payment_origins/`,
  dashboardPayments: id =>
    `/api/merchants/${id}/merchant_reports/dashboard_payments/`,
  dashboardAutopays: id => `/api/merchants/${id}/merchant_reports/autopays/`,
  dashboardMessages: id => `/api/merchants/${id}/merchant_reports/messaging/`,
  SSOAuth: () => `/api/sso-auth/`,
  freshBooksAuth: () => `/api/freshbooks/auth/`,
  listOauthLinks: () => `/api/oauth-links/`,
  listReconciliation: merchant_org_id =>
    `/api/merchants/${merchant_org_id}/reconciliation/`,
  listFailedActions: merchant_org_id =>
    `/api/merchants/${merchant_org_id}/failed-actions/`,
  resolveFailedAction: (merchant_org_id, id) =>
    `/api/merchants/${merchant_org_id}/failed-actions/${id}/resolve/`,
  retryFailedAction: (merchant_org_id, id) =>
    `/api/merchants/${merchant_org_id}/failed-actions/${id}/retry/`,
  resolvePayment: merchant_org_id =>
    `/api/merchants/${merchant_org_id}/resolve_payment/`,
  retryPostPayment: merchant_org_id =>
    `/api/merchants/${merchant_org_id}/retry_post_payment/`,
  retryPostPaymentBulk: merchant_org_id =>
    `/api/merchants/${merchant_org_id}/retry_post_payment_bulk/`,
  listInbox: merchant_org_id => `/api/merchants/${merchant_org_id}/inbox/`,
  retrieveInbox: (merchant_org_id, id) =>
    `/api/merchants/${merchant_org_id}/inbox/${id}/`,
  listTransactionReport: merchant_org_id =>
    `/api/merchants/${merchant_org_id}/report-transactions/`,
  listPaymentReport: merchant_org_id =>
    `/api/merchants/${merchant_org_id}/payment_report/`,
  exportTransactionReport: merchant_org_id =>
    `/api/merchants/${merchant_org_id}/export_transactions/`,
  exportDepositReport: merchant_org_id =>
    `/api/merchants/${merchant_org_id}/export_deposits/`,
  exportIncommDepositReport: merchant_org_id =>
    `/api/merchants/${merchant_org_id}/export_incomm_deposits/`,
  exportPaymentReport: merchant_org_id =>
    `/api/merchants/${merchant_org_id}/export_payments/`,
  exportPaymentRequestReport: merchant_org_id =>
    `/api/merchants/${merchant_org_id}/export_payment_requests/`,
  exportProjectionReport: merchant_org_id =>
    `/api/merchants/${merchant_org_id}/export_projections/`,
  exportACHReport: merchant_org_id =>
    `/api/merchants/${merchant_org_id}/export_payment_returns/`,
  exportPromiseReport: merchant_org_id =>
    `/api/merchants/${merchant_org_id}/export_promises/`,
  refundPayment: merchant_org_id =>
    `/api/merchants/${merchant_org_id}/refund_payment/`,
  cancelPayment: merchant_org_id =>
    `/api/merchants/${merchant_org_id}/cancel_scheduled_payment/`,
  refundTransaction: merchant_org_id =>
    `/api/merchants/${merchant_org_id}/refund_transaction/`,
  promiseReport: merchant_org_id =>
    `/api/merchants/${merchant_org_id}/report-promises`,
  propayWebhook: () => `/api/webhooks/propay/`,
  managerReport: merchant_org_id =>
    `/api/merchants/${merchant_org_id}/manager-report/`,
  exportManagerReport: merchant_org_id =>
    `/api/merchants/${merchant_org_id}/manager-report/export_user_actions/`,
  listRoles: merchant_org_id => `/api/merchants/${merchant_org_id}/roles/`,
  roleCreate: merchant_org_id => `/api/merchants/${merchant_org_id}/roles/`,
  roleDetail: (merchant_org_id, role_id) =>
    `/api/merchants/${merchant_org_id}/roles/${role_id}/`,
  achReturnReport: merchant_org_id =>
    `/api/merchants/${merchant_org_id}/ach-return-report`,
  retrieveBinInfo: (merchant_org_id, bin) =>
    `/api/merchants/${merchant_org_id}/bins/${bin}/`,
  movePaymentsBetweenCustomers: (merchant_org_id, customer_id) =>
    `/api/merchants/${merchant_org_id}/customers/${customer_id}/move_payment/`,
  voidCustomer: (merchant_org_id, id) =>
    `/api/merchants/${merchant_org_id}/customers/${id}/`,
  cancelPendingMessageBatch: (merchant_org_id, id) =>
    `/api/merchants/${merchant_org_id}/message_batches/${id}/cancel_pending/`,
  listAvailableUpc: () => `/api/merchants/available_upc/`,
  listPropayBusinessTypes: () => `/api/merchants/propay_business_types/`,
  failedAutoPaymentsReport: merchant_org_id =>
    `/api/merchants/${merchant_org_id}/failed_payments_report/`,
  getPricingTemplates: merchant_id =>
    `/api/merchants/${merchant_id}/get_pricing_templates/`,
  merchantDashboard: () => `/api/customer_success_merchant_dashboard/`
}
