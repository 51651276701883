const DashboardHome = () =>
  import('@/views/CustomerSuccessDashboard/DashboardHome/DashboardHome.vue')

export default [
  {
    path: '/customers-success-dashboard',
    component: DashboardHome,
    name: 'DashboardHome',
    meta: {
      require_roles: ['super_admin'],
      hideNav: true
    }
  }
]
