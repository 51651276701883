


































































import Vue from 'vue'
import moment from 'moment'

export default Vue.extend({
  name: 'ChatMessage',
  props: {
    message: {
      type: Object,
      required: true
    },
    lastMessage: {
      type: Object,
      required: false
    },
    showTime: {
      type: Boolean,
      default: true
    },
    showName: {
      type: Boolean,
      default: true
    },
    type: {
      type: String,
      required: true
    },
    showIcon: {
      type: Boolean,
      default: true
    },
    showDate: {
      type: Boolean,
      default: false
    },
    customer: {
      type: Object,
      required: true
    },
    language: {
      type: String,
      required: false,
      default: 'en-US'
    }
  },
  computed: {
    username(): string {
      if (this.isSystemMessage()) {
        return 'System message'
      }

      if (this.message.sender) {
        return `${this.message.sender.first_name} ${this.message.sender.last_name}`
      }

      if (this.customer) {
        return `${this.customer.first_name} ${this.customer.last_name}`
      }

      return 'Undefined'
    }
  },
  methods: {
    moment,
    isSystemMessage() {
      if (!this.message?.sender) return false
      return this.message?.sender?.roles?.includes('super_admin')
    },
    openImage(url) {
      window.open(url, '_blank')
    },
    vcard(type) {
      return type === 'text/vcard' || type === 'text/x-vcard'
    },
    translated(message) {
      return this.language == message.language
        ? message.text
        : message.translations[this.language] ?? message.text
    }
  }
})
