<template>
  <div id="inbox-container" class="h-flex">
    <b-list-group id="contact-list" flush>
      <div id="search-inbox-item">
        <div id="search-inbox-group">
          <div class="filters">
            <b-dropdown
              variant="blue"
              :text="selectedFieldText"
              size="sm"
              class="selected-field"
            >
              <b-dropdown-item
                v-for="option in filters.fieldValues"
                :key="option.value"
                @click="setSearchField(option.value)"
              >
                {{ option.text }}
              </b-dropdown-item>
            </b-dropdown>

            <b-button
              data-cy="additional-filters"
              title="Additional Filters"
              @click="displayFilters"
            >
              <fa-icon :icon="['fas', 'filter']" />
            </b-button>
            <b-button @click="onResetFilters">Reset Filters</b-button>
            <b-button
              v-if="canComposeMassMessage && merchant.allow_group_message"
              class="mass-message-button"
              data-cy="compose"
              @click="compose"
            >
              <fa-icon icon="pen" /> Group Message
            </b-button>
          </div>

          <b-input-group-text class="search-icon">
            <div class="text-field">
              <b-form-input
                id="search-inbox-input"
                v-model="filters.search"
                placeholder="Search..."
                type="text"
                size="sm"
                data-cy="search-inbox"
                @keyup.enter="search"
              />
              <b-input-group-append v-if="filters.search">
                <b-button
                  size="sm"
                  variant="outline"
                  class="search-button"
                  @click="filters.search = ''"
                >
                  <fa-icon :icon="['fas', 'times-circle']" />
                </b-button>
              </b-input-group-append>
            </div>
            <b-button
              data-cy="search-button"
              @click="search"
            >
              <fa-icon icon="search" />
            </b-button>
          </b-input-group-text>
        </div>
      </div>

      <transition-group name="contact" tag="div" class="relative-parent">
        <b-list-group-item
          v-for="customerMessage of customerMessages"
          :key="customerMessage.customer_id"
          :class="activeContact === customerMessage.customer_id ? 'active' : ''"
          class="h-flex contact rounded-0"
          :contact="customerMessage"
          @click="getContact(customerMessage)"
        >
          <b-avatar :text="initials(customerMessage)" />

          <div class="v-flex preview">
            <div class="h-flex justify-content-between">
              <span class="ellipsis">
                {{ name(customerMessage) }} {{ external_id(customerMessage) }}
              </span>
              <small>
                {{
                  customerMessage.last_message_created | moment('ddd h:mm A')
                }}
              </small>
            </div>

            <small class="preview-body">
              {{ customerMessage.last_message_body }}
            </small>
          </div>

          <b-badge
            :class="!customerMessage.unread_messages ? 'invisible' : ''"
            class="unread-messages"
            variant="danger"
          >
            {{ customerMessage.unread_messages }}
          </b-badge>
        </b-list-group-item>
        <infinite-loading
          key="infinite"
          :identifier="infiniteId"
          @infinite="load"
        >
          <template #no-more>
            <b-list-group-item key="end-of-conversation">
              End of Conversations
            </b-list-group-item>
          </template>

          <template #no-results>
            <b-list-group-item key="no-results">
              No Results Found
            </b-list-group-item>
          </template>
        </infinite-loading>
      </transition-group>
    </b-list-group>

    <Chat
      v-if="customer && customer.id"
      v-model="customer"
      :account-id="accountID()"
    />
    <div
      v-else
      id="inbox-chat-placeholder"
      class="v-flex justify-content-center w-100 text-center"
    >
      <h2>Select a Customer</h2>
    </div>
    <ComposeMessage
      id="compose-message-modal"
      :customers="customers"
      :selectedRecipients="[]"
    />

    <ValidationObserver ref="observer" v-slot="{ invalid }" slim>
      <b-modal
        v-bind="$attrs"
        id="inbox-filters-modal"
        ref="modal"
        cancel-variant="Cancel"
        ok-title="Search"
        data-cy="inbox-filters"
        class="inbox-filters"
        hide-header
        centered
        :ok-disabled="invalid"
        @ok="clear"
        @cancel="onCancel"
      >
        <ValidationProvider
          v-slot="{ errors }"
          slim
          rules="required"
          mode="aggressive"
        >
          <b-form-group label="Location" label-for="merchantLocation">
            <b-select
              v-model="filters.location"
              cy="inbox-location"
              name="merchantLocation"
              :options="locationList"
            />
            <div class="errors">
              <small>{{ errors[0] }}</small>
            </div>
          </b-form-group>
        </ValidationProvider>
      </b-modal>
    </ValidationObserver>
  </div>
</template>

<script>
import { AuthMixin } from '@/mixins/AuthMixin/AuthMixin'
import { BAvatar } from 'bootstrap-vue'
import { Component, Vue, Watch, Mixins } from 'vue-property-decorator'
import { mapActions, mapGetters } from 'vuex'
import { some, uniqBy } from 'lodash-es'
import InfiniteLoading from 'vue-infinite-loading'
import ComposeMessage from '@/components/Modals/ComposeMessage.vue'
import VueMoment from 'vue-moment'
Vue.use(VueMoment)

import Chat from '@/components/Chat.vue'

@Component({
  components: {
    BAvatar,
    Chat,
    InfiniteLoading,
    ComposeMessage
  },
  computed: {
    ...mapGetters({
      merchant: 'currentMerchant',
      customer: 'currentCustomer',
      locations: 'locations'
    }),
    selectedFieldText() {
      const selectedField = this.filters.fieldValues.find(
        option => option.value === this.filters.selectedField
      )
      return selectedField ? selectedField.text : 'Search by'
    }
  },
  methods: {
    ...mapActions(['getCustomer'])
  }
})
export default class Inbox extends Mixins(AuthMixin) {
  cursor = ''
  page_size = 25
  infiniteId = +new Date()
  activeContact = null
  customers = []
  customerMessages = []
  defaultField = 'customer-id'
  lastSearch = ''
  filters = {
    search: '',
    location: '',
    selectedField: this.defaultField,
    fieldValues: [
      { text: 'Name', value: 'name' },
      { text: 'Customer ID', value: 'customer-id' },
      { text: 'Phone Number', value: 'phone-number' }
    ]
  }

  async created() {
    this.setTitle()

    // TODO: Fix the event bus behavior.
    // If you load straight here and there are notifications, they
    // currently get pushed out as they load, which will mess up the data.
    setTimeout(() => this.$eventBus.on('chat', this.onChat), 5000)
    this.filters.location = 'all'
  }

  @Watch('customer')
  setTitle() {
    const crumb = [{ crumbTitle: 'Inbox' }]
    const fullName = this.customer?.first_name + ' ' + this.customer?.last_name
    const identifier = this.customer?.external_id ?? ' '

    if (this.filters.location) {
      const locationID = this.filters.location
      const currentLocation = this.locations.find(
        location => location.id === locationID
      )
      if (currentLocation?.id) {
        crumb.push({ crumbTitle: currentLocation.name })
      }
    }

    if (this.customer?.id) {
      crumb.push({
        crumbTitle: fullName + ' ' + identifier,
        directTo: 'CustomerAccountDetails'
      })

      this.$route.params.customerID = this.customer.id
    }

    this.$store.dispatch('setPageTitle', crumb)
  }

  async getCustomerInfo(params) {
    const customerInfo = await this.axios.post(
      this.$endpoints.listMerchantCustomerAccounts(
        this.$route.params.merchantID
      ),
      {
        params: {
          ...params
        }
      }
    )
    return customerInfo.data
  }

  compose() {
    this.getCustomerInfo().then(res => {
      res.results = res.results.map(item => {
        const customerName = item.first_name + ' ' + item.last_name
        const customerInfo = {
          name: customerName,
          email: item.customer.email,
          phone: item.customer.sms_phone_number,
          text: customerName,
          customerID: item.customer.id,
          accountID: item.account.id
        }

        return customerInfo
      })
      this.customers = res.results
      this.$bvModal.show('compose-message-modal')
    })
  }

  destroyed() {
    this.$store.commit('clearCurrentCustomer')
    this.$store.commit('clearCurrentAccount')

    this.$eventBus.off('chat', this.onChat)
  }

  async onChat(chat) {
    const id = chat.customerId
    const response = await this.axios.get(
      this.$endpoints.retrieveInbox(this.merchant.id, id)
    )

    const contact = response.data
    if (contact) {
      const index = this.customerMessages.findIndex(m => m.id === id)
      if (index !== -1) {
        this.customerMessages.splice(index, 1)
      }

      const show = contact => {
        if (!this.filters.search) return true

        const re = new RegExp(this.filters.search.trim(), 'i')
        return some(
          [contact?.first_name, contact?.last_name, contact?.last_message_body],
          v => re.test(v)
        )
      }

      if (show(contact)) this.customerMessages.unshift(contact)
    }
  }

  getContact(messageCustomer) {
    const customer = messageCustomer
    this.activeContact = messageCustomer.customer_id
    messageCustomer.unread_messages = 0
    this.getCustomer({
      customerID: customer.customer_id,
      checkIntegration: false
    })
  }

  search(force = false) {
    // this triggers the load() function, that does the actual search
    if(!this.filters.search && !this.lastSearch && !force) return
    this.lastSearch = this.filters.search
    this.clear()
  }

  onResetFilters() {
    this.filters.location = 'all'
    this.filters.search = ''
    this.filters.selectedField = this.defaultField
    this.setTitle()
    this.search(true)
  }

  setSearchField(field) {
    this.filters.selectedField = field
  }

  async load(state) {
    try {
      let search = this.filters.search.trim().replaceAll(/[()+]+/g, '')
      let field = this.filters.selectedField
      const params = { cursor: this.cursor, page_size: this.page_size }
      if (search) Object.assign(params, { search: search, field: field })
      if (this.filters.location !== 'all') {
        Object.assign(params, { location: this.filters.location })
      }
      const response = await this.axios.get(
        this.$endpoints.listInbox(this.merchant.id),
        { params: params }
      )

      const customerMessages = response?.data?.results
      if (customerMessages.length) {
        this.customerMessages = uniqBy(
          [...this.customerMessages, ...customerMessages],
          'customer_id'
        )
      }

      const stillActive = this.customerMessages.find(
        c => c.customer_id === this.activeContact
      )

      if (!stillActive) {
        this.activeContact = null
        this.$store.commit('clearCurrentCustomer')
      }

      state.loaded()
      if (!response.data.next) state.complete()
      this.setTitle()

      const next_url = new URL(response?.data?.next)
      const next_params = new URLSearchParams(next_url?.search)
      this.cursor = next_params.get('cursor')
    } catch {
      state.complete()
    }
  }

  clear() {
    this.cursor = ''
    this.customerMessages = []
    this.infiniteId += 1
  }

  accountID() {
    return this.customer?.accounts?.[0].id
  }

  initials(customerMessage) {
    const initials =
      customerMessage?.first_name[0] + customerMessage?.last_name[0]
    return initials.toUpperCase()
  }

  name(messageCustomer) {
    return messageCustomer?.first_name + ' ' + messageCustomer?.last_name
  }

  external_id(customerMessage) {
    return customerMessage?.external_id
      ? `| ${customerMessage?.external_id}`
      : ''
  }

  displayFilters() {
    this.$bvModal.show('inbox-filters-modal')
  }

  async onCancel() {
    this.filters.location = 'all'
    this.setTitle()
  }

  get locationList() {
    const locations = this?.locations || []
    const normalizedList = locations.map(location => {
      return {
        value: location.id,
        text: location.name
      }
    })
    normalizedList.unshift({
      value: 'all',
      text: 'All Locations'
    })
    return normalizedList
  }

  get canComposeMassMessage() {
    if (this.$store.getters.currentMerchant.check_custom_roles) {
      return this.hasPermission([
        'super_admin',
        'admin',
        'is_customer_campaign_allowed'
      ])
    } else {
      return this.hasPermission(['super_admin', 'admin', 'staff'])
    }
  }
}
</script>

<style lang="scss" scoped>
#compose {
  position: absolute;
  bottom: 0;

  display: flex;
  justify-content: center;
  margin: 2rem;
  padding: 0.4em;
}

#inbox-container {
  width: 100%;
  height: 100%;
}

#contact-list {
  width: 40vw;
  min-width: 250px;
  height: 100%;
  overflow: auto;
}

.contact {
  transition-duration: 1s;
  transition-property: opacity, transform;

  &:hover {
    margin: 0 !important;

    background: lighten(rgba(160, 186, 205, 1), 20%);
    border-color: transparent !important;
    border-top-width: 0 !important;
    cursor: pointer;
  }
}

.contact-enter,
.contact-leave-to {
  opacity: 0;
}

.contact-enter {
  transform: translateY(0);
}

.contact-leave-to {
  transform: translateY(100vh);
}

// Stops the contacts from snapping to weird widths during leave-active
.relative-parent {
  position: relative;
}

.contact-leave-active {
  position: absolute;

  width: 100%;
}

#search-inbox-item {
  padding: 4px;
}

#search-inbox-group {
  display: flex;
  flex-direction: column;
  margin: 0;

  border: 0 transparent;
  .search-icon {
    margin-right: 0.25rem;
    padding: 0;

    background-color: transparent;
    border: 0;

    button {
      margin-left: 1rem;
      padding: 0 0.5em;
    }
  }
  .filters {
    display: flex;
    gap: 4px;
    width: 100%;
    margin: 10px 0 0;
    .selected-field {
      width: 160px;
    }

    .mass-message-button {
      margin: 0 5px 0 auto;
      svg {
        margin-right: 5px;
      }
    }
  }

  #search-inbox-input {
    width: 100%;

    border: 0;
  }

  .text-field {
    display: flex;
    width: 100%;
    height: 38px;
    margin: 10px 0;

    border: 1px solid #ccc;
  }

  .input-group-text {
    .btn {
      min-width: 38px;
      height: 38px;
      margin: 0 0 0 7px;
    }
  }
}

.active {
  margin: 0 !important;

  background: linear-gradient(
    to right,
    rgba(160, 186, 205, 1) 0%,
    rgb(170, 193, 197) 50%,
    rgba(160, 186, 205, 1) 100%
  );
  border-color: transparent !important;
  border-top-width: 0 !important;
}

.preview {
  flex: 1;
  width: 100%;
  margin-right: 1em;
  margin-left: 1em;
  overflow: hidden;

  .preview-body {
    overflow: hidden;

    white-space: nowrap;
    text-overflow: ellipsis;
  }
}

.unread-messages {
  align-self: center;
  justify-self: flex-end;
}

#chat-window {
  height: 100%;

  border: 0;
}

#inbox-chat-placeholder {
  border-left: 1px solid;
}
</style>
