<template>
  <ValidationObserver
    v-slot="{ invalid }"
    tag="form"
    @submit="onSubmit($event)"
  >
    <b-modal
      id="compose-message-modal"
      data-cy="compose-message-modal"
      class="compose-message-modal"
      title="New Mass Text Message"
      centered
      size="lg"
      @show="onShow"
      @hidden="onHidden"
    >
      <div>
        <b-alert
          v-if="recipientType === 'group' && selectedCustomers.length"
          show
          variant="info"
        >
          {{ selectedCustomers.length }} customer(s) selected
        </b-alert>
        <span>
          This message will appear in each of the customer's feeds. <br />
          The customers will not know who else you sent this message to.
        </span>
      </div>
      <b-card id="chat-window" class="v-flex" no-body>
        <div id="snippet-triggers" class="rack compress">
          <div id="template-select">
            <span>Add Text From Template:</span>
            <b-form-select
              :options="messageTemplates"
              data-cy="message-templates"
              @change="onTemplate"
            />
          </div>
        </div>
        <span v-if="recipientType === 'csv'">
          Total Customer Count: {{ csvData.length }}
        </span>
        <div id="recipient-option-container">
          <!-- TODO - option to use customer-groups -->
          <!-- <b-form-group v-slot="{ ariaDescribedby }" label="Recipients:">
            <b-form-radio-group
              v-model="recipientType"
              class="group-options"
              :options="recipientOptions"
              :aria-describedby="ariaDescribedby"
              name="radio-inline"
              @change="onType"
            />
          </b-form-group> -->
          <div v-if="recipientType === 'group'" class="group-select">
            <b-form-select
              v-model="currentGroupID"
              :options="groups"
              name="name"
              @change="onGroup"
            >
              <template #first>
                <b-form-select-option :value="null" disabled>
                  Select a group
                </b-form-select-option>
              </template>
            </b-form-select>

            <ValidationProvider rules="required">
              <b-form-input
                v-model="batchName"
                placeholder="Batch name"
                data-cy="batch-name"
              />
            </ValidationProvider>
          </div>
          <div
            v-else-if="recipientType === 'csv'"
            id="csv-upload-container"
            class="d-flex"
          >
            <b-form-file
              v-model="csvFile"
              :disabled="!!selectedCustomers.length"
              class="import-csv"
              placeholder="Choose a file or drop it here..."
              browse-text="Import CSV"
              data-cy="import"
              @input="onFile($event)"
            />
            <b-form-select
              id="csv-key-select"
              v-model="csvDataKey"
              :disabled="!csvFile"
            >
              <option :value="''" disabled>-- Customer external ID --</option>
              <option
                v-for="(value, key) of csvData[0]"
                :key="key"
                :value="key"
              >
                {{ key }}
              </option>
            </b-form-select>
          </div>

          <div v-if="recipientType === 'csv'" id="recipient-select">
            <div class="d-flex">
              <div id="group-name-input">
                <!-- TODO - option to create customer-group name -->
                <!-- <ValidationProvider :rules="isUpdatingGroup ? 'required' : ''">
                  <b-form-input
                    v-model="currentGroupName"
                    placeholder="Group name"
                  />
                </ValidationProvider> -->
                <ValidationProvider rules="required">
                  <b-form-input
                    v-model="batchName"
                    placeholder="Batch name"
                    data-cy="batch-name"
                  />
                </ValidationProvider>
              </div>
              <!-- TODO - option to save customer-groups -->
              <!-- <b-form-checkbox v-model="isUpdatingGroup">
                {{ currentGroupID ? 'Update' : 'Save' }}
                group
              </b-form-checkbox> -->
            </div>
          </div>
        </div>

        <template #footer>
          <b-form
            id="chat-input"
            :class="{ 'chat-input-disabled': !canFreeType }"
          >
            <div class="mt-2">
              <SnippetTrigger
                id="payment-link"
                label="Payment Link"
                type="dark"
                data-cy="payment-link-snippet"
                @click.native="addPaymentLink()"
              />

              <b-button
                id="chat-info"
                v-b-tooltip.hover
                title="Recipients recieve what's below (with certain values populated)"
              >
                <fa-icon id="payment-link" icon="info" />
              </b-button>

              <div class="display-inline-block clear-chat-container">
                <b-button
                  v-if="!canFreeType"
                  class="clear-chat"
                  small
                  @click="clearChat"
                >
                  <fa-icon icon="times-circle" />
                </b-button>
              </div>
            </div>
            <ValidationProvider rules="required">
              <b-textarea
                id="chat-input-text"
                v-model="messageBody"
                :placeholder="chatDisplay"
                no-resize
                :disabled="!canFreeType"
              />
            </ValidationProvider>
          </b-form>
        </template>
      </b-card>

      <template #modal-footer>
        <div id="save-template">
          <b-checkbox
            v-if="canUpdateTemplates"
            :checked="isSaveTemplate"
            @change="toggleSaveTemplate"
          >
            Save as New Template
          </b-checkbox>
          <div v-if="isSaveTemplate" role="group">
            <ValidationProvider
              :rules="`required|valid-template:${validTemplateName}`"
            >
              <b-form-input
                id="input-live"
                v-model="currentTemplateName"
                :state="validTemplateName"
                placeholder="Template name"
                @change="onTemplateName"
              />
              <b-form-invalid-feedback id="input-live-feedback">
                Existing template name
              </b-form-invalid-feedback>
            </ValidationProvider>
          </div>
        </div>
        <b-button
          type="submit"
          :disabled="preventSubmit(invalid)"
          data-cy="send"
          @click="onSubmit"
        >
          <span v-if="!isBusy">{{ !canClose ? 'Send' : 'Close' }}</span>
          <b-spinner v-else small />
        </b-button>
      </template>
    </b-modal>
  </ValidationObserver>
</template>

<script>
import { AuthMixin } from '@/mixins/AuthMixin/AuthMixin'
import { Component, Vue, Mixins, Prop, Watch } from 'vue-property-decorator'
import { mapGetters } from 'vuex'
import Papa from 'papaparse'
import SnippetTrigger from '@/components/SnippetTrigger/SnippetTrigger/SnippetTrigger.vue'
import { uniqBy } from 'lodash'
import { extend, ValidationObserver, ValidationProvider } from 'vee-validate'
import VueMoment from 'vue-moment'

Vue.use(VueMoment)

extend('valid-template', {
  validate(value, { is_valid }) {
    return value && is_valid === 'null' ? true : false
  },
  params: ['is_valid'],
  message: 'Must be empty or 4 digits'
})

@Component({
  computed: {
    ...mapGetters([])
  },
  components: {
    SnippetTrigger,
    ValidationObserver,
    ValidationProvider
  }
})
export default class ComposeMessage extends Mixins(AuthMixin) {
  @Prop({ default: [] }) customers
  @Prop() listGroup
  @Prop({ default: [] }) selectedRecipients

  batchName = ''
  canClose = false
  csvFile = null
  csvData = []
  csvDataKey = ''
  currentGroupID = ''
  currentGroupName = ''
  currentTemplateName = ''
  currentTemplate = null
  groups = []
  isBusy = false
  isSaveTemplate = false
  isUpdatingGroup = false
  messageBody = ''
  messageTemplates = []
  optoutCustomers = []
  recipientOptions = [
    { text: 'Group', value: 'group' },
    { text: 'CSV Upload', value: 'csv' }
  ]
  selectedCustomers = []
  unknownCustomers = []
  validTemplateName = null
  newMessageTemplateId = ''

  isOptedOut(event) {
    // TODO - O(n^2) time complexity, needs to be faster
    for (const ou of this.optoutCustomers) {
      if (ou.id === event.customerID) return true
    }
    return false
  }

  mounted() {
    this.getGroups()
    this.getMessageTemplates()
  }

  @Watch('csvFile')
  clearCSV(newVal) {
    if (newVal) return
    this.csvFile = null
    this.csvData = []
    this.csvDataKey = ''
  }

  async addPaymentLink() {
    this.messageBody = this.messageBody + ' {{ payment_link }}'
  }

  clearChat() {
    this.messageBody = ''
  }

  clearGroup() {
    this.currentGroupID = ''
    this.currentGroupName = ''
    this.isUpdatingGroup = false
  }

  clearSelectedCustomers() {
    this.optoutCustomers = []
    this.selectedCustomers = []
  }

  async createGroup(customerIDs) {
    const payload = {
      customers: customerIDs,
      name: this.currentGroupName
    }
    if (this.csvFile) payload['find_by_external'] = true

    let createResponse = {}
    if (this.currentGroupID) {
      createResponse = await this.axios
        .put(
          this.$endpoints.updateCustomerGroup(
            this.$route.params.merchantID,
            this.currentGroupID
          ),
          payload
        )
        .catch(err => {
          this.clearCSV()
          console.log(err)
        })
    } else {
      createResponse = await this.axios
        .post(
          this.$endpoints.createCustomerGroup(this.$route.params.merchantID),
          payload
        )
        .catch(err => {
          this.clearCSV()
          console.log(err)
        })
    }
    this.getGroups()
    return (this.currentGroupID = createResponse.data.id)
  }

  filterCustomers() {
    // TODO - O(n^2) time complexity, needs to be faster
    for (const oc of this.optoutCustomers) {
      this.selectedCustomers = this.selectedCustomers.filter(
        item => item.customerID != oc.id
      )
    }
    this.optoutCustomers = []
  }

  filterCSVData() {
    // TODO - O(n^2) time complexity, needs to be faster
    let ids = []
    for (const oc of this.unknownCustomers) {
      ids = this.csvData
        .map(customer => customer[this.csvDataKey])
        .filter(customer => Boolean(customer) && customer != oc)
    }
    return ids
  }

  getGroups(groupID = this.$route.params.merchantID) {
    return this.axios
      .get(this.$endpoints.listCustomerGroups(groupID), {
        params: { page: 1, page_size: 1000 }
      })
      .then(response => {
        response.data.results = response.data.results.map(item => {
          item.text = item.name
          item.value = item.id
          return item
        })
        this.groups = [
          { text: '-- New group --', value: '', id: '' },
          ...response.data.results.filter(group => !!group.customer_m2m.length)
        ]
      })
  }

  async getMessageTemplates() {
    try {
      const response = await this.axios.get(
        this.$endpoints.listMessageTemplates(this.$route.params.merchantID),
        {
          params: { page: 1, page_size: 1000 }
        }
      )
      const activeTemplates = response.data.results.filter(
        template => template.active
      )
      this.messageTemplates = activeTemplates.map(template => {
        return { value: template, text: template.name }
      })
    } catch (err) {
      console.log('error', err)
    }
  }

  async getOptouts(customerIDs) {
    const payload = { customers: customerIDs }
    if (this.csvFile) payload['find_by_external'] = true

    const response = await this.axios.post(
      this.$endpoints.retrieveCustomerOptouts(this.$route.params.merchantID),
      payload
    )
    this.optoutCustomers = response.data.optout_customers
    if (this.csvFile) {
      this.selectedCustomers = response.data.customers.map(item => {
        const customerName = item.first_name + ' ' + item.last_name
        const customerInfo = {
          name: customerName,
          customerID: item.id
        }
        return customerInfo
      })
    }
    this.unknownCustomers = response.data.unknown_customers
    if (this.unknownCustomers.length) {
      this.$toasted.global.error({
        message: `Unable to find ${this.unknownCustomers.length} customer(s)`
      })
    }
    this.onGroup()
    return this.file
      ? this.filterCSVData()
      : this.selectedCustomers.map(items => items.customerID)
  }

  onFile(file) {
    if (!file) return
    const config = {
      header: true,
      complete: results => {
        this.csvData = results.data
      },
      error: err => {
        console.log(err)
      },
      skipEmptyLines: true
    }
    Papa.parse(file, config)
  }

  onGroup() {
    const selected = this.groups.find(group => group.id === this.currentGroupID)
    if (selected?.name) this.currentGroupName = selected?.name
    if (!this.currentGroupID) return
    this.selectedCustomers = this.customers.filter(item =>
      selected?.customer_m2m?.includes(item.customerID)
    )
  }

  onHidden() {
    this.clearCSV()
    this.clearSelectedCustomers()
    this.clearGroup()
    this.clearChat()
    this.batchName = ''
    this.isSaveTemplate = false
    this.validTemplateName = null
    this.canClose = false
  }

  onShow() {
    if (this.selectedRecipients.length) {
      this.selectedCustomers = uniqBy(this.selectedRecipients, 'customerID')
    }
    const group = this.groups.find(
      group => group.id === this.listGroup?.id
    ) || { id: '' }
    this.currentGroupID = group.id
    if (this.currentGroupID) this.onGroup()
  }

  toggleSaveTemplate() {
    this.isSaveTemplate = !this.isSaveTemplate
    this.currentTemplateName = ''
  }

  async onSubmit() {
    if (this.canClose) return this.$bvModal.hide('compose-message-modal')

    this.isBusy = true

    await this.createTemplate()

    let customerIDs = this.csvFile
      ? this.csvData
          .map(customer => customer[this.csvDataKey])
          .filter(customer => customer)
      : this.selectedCustomers.map(customer => customer.customerID)

    this.sendCustomersMessage(customerIDs)
    this.$toasted.show(
      `Message group now processing for ${customerIDs.length} customer(s)`,
      {
        duration: 5000,
        position: 'bottom-center'
      }
    )
    this.isBusy = false
    this.clearCSV()
    this.$emit('clearCustomers')
    this.$bvModal.hide('compose-message-modal')
  }

  async onTemplate(event) {
    const message = event.message_bodies.filter(body => {
      return body.body_type === 'TEXT'
    })[0]
    this.messageBody = this.messageBody + message.message_body
  }

  onTemplateName() {
    const templateNames = this.messageTemplates.map(template => {
      return template.text
    })
    if (templateNames.includes(this.currentTemplateName)) {
      this.isBusy = false
      this.validTemplateName = false
      return
    }
    this.validTemplateName = null
  }

  onType(event) {
    if (event === 'csv') {
      this.clearGroup()
      this.clearSelectedCustomers()
    } else {
      this.clearCSV()
    }
  }

  preventSubmit(invalid) {
    if (this.isBusy) return this.isBusy
    if (this.csvFile) return !(!invalid && this.csvDataKey)
    if (this.selectedCustomers.length) return invalid
    return true
  }

  async createTemplate() {
    const requestType = this.$route.params.messageTemplateID ? 'PUT' : 'POST'

    // Generate a unique name using time
    if (this.isSaveTemplate === false) {
      const now = Math.floor(Date.now() / 1000) // Time in seconds
      this.currentTemplateName = 'messageTemplate' + now.toString()
    }

    const payload = {
      name: this.currentTemplateName,
      message_bodies: [
        {
          language: 'en-US',
          body_type: 'TEXT',
          message_body: this.messageBody
        },
        {
          language: 'en-US',
          body_type: 'EMAIL',
          message_body: this.messageBody
        }
      ],
      type: 'UNSCHEDULED',
      description: 'group message template',
      active: this.isSaveTemplate,
      is_visible: this.isSaveTemplate
    }

    try {
      const response = await this.axios({
        method: requestType,
        url: this.messageTemplateSubmitTo,
        data: payload
      })
      this.newMessageTemplateId = response.data.id

      if (this.isSaveTemplate) {
        this.$toasted.show('Template saved!', {
          duration: 2000,
          position: 'bottom-center'
        })
      }
    } catch (error) {
      this.$refs.observer.setErrors(error.response.data)
    }
  }

  sendCustomersMessage(customerIDs) {
    const payload = {
      message_template_id: this.newMessageTemplateId,
      message: this.messageBody,
      customers: customerIDs,
      batch_name: this.batchName,
      is_csv_imported: !!this.csvData?.length
    }

    try {
      this.axios.post(
        this.$endpoints.sendCustomersMessage(this.$route.params.merchantID),
        payload
      )
    } catch (err) {
      console.log(err)
    }
    return (this.canClose = true)
  }

  get recipientType() {
    return this.selectedCustomers.length ? 'group' : 'csv'
  }

  get messageTemplateSubmitTo() {
    return this.$route.params.messageTemplateID
      ? this.$endpoints.updateMessageTemplate(
          this.$route.params.merchantID,
          this.$route.params.messageTemplateID
        )
      : this.$endpoints.createMessageTemplate(this.$route.params.merchantID)
  }

  get canFreeType() {
    if (this.$store.getters.currentMerchant.check_custom_roles) {
      return this.hasPermission([
        'super_admin',
        'admin',
        'is_customer_free_text_allowed'
      ])
    } else {
      return this.hasPermission(['super_admin', 'admin', 'staff'])
    }
  }

  get canUpdateTemplates() {
    if (this.$store.getters.currentMerchant.check_custom_roles) {
      return this.hasPermission([
        'super_admin',
        'admin',
        'is_template_update_allowed'
      ])
    } else {
      return this.hasPermission(['super_admin', 'admin', 'staff'])
    }
  }

  get chatDisplay() {
    return this.canFreeType
      ? 'Enter your message...'
      : 'Choose a template to chat...'
  }
}
</script>

<style lang="scss">
/* stylelint-disable */
.multiselect {
  min-height: 38px;

  .multiselect__tags {
    .multiselect__tag {
      background: $button-blue;
      i:hover {
        background: darken($button-blue, 10%);
      }
      i::after {
        color: white;
      }
    }
  }
  .multiselect__option--highlight {
    background: lighten($blue, 25%);
    &:after {
      background: lighten($blue, 25%);
    }
  }
}
.multiselect__select {
  height: 38px;
}
.multiselect__tags {
  height: 38px;
  min-height: 38px;

  border: 1px solid #ced4da;
}
div.multiselect__tags-wrap {
  display: none;
}
#compose-message-modal {
  .btn-primary {
    width: 20%;

    background-color: $button-blue;
  }
}
.group-options {
  .custom-control-input:checked ~ .custom-control-label::before {
    border-color: #304258;
    background-color: #304258;
  }
}
</style>
<style lang="scss" scoped>
#snippet-triggers {
  font-size: 0.9rem;
}

#template-select {
  margin-top: 20px;
  margin-bottom: 20px;
  span {
    float: left;
    margin-bottom: 5px;

    font-size: 16px;
  }
}
#recipient-option-container {
  .group-select {
    display: flex;
    gap: 10px;
    margin-bottom: 1rem;

    > * {
      flex: 1;
    }

    .custom-checkbox {
      width: 20%;
      margin-left: 1rem;
    }

    .clear-customers {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 38px;

      svg {
        width: 1.25em;
        height: 1.25em;

        &:hover {
          cursor: pointer;
        }
      }
      .enabled-action {
        color: $red;

        &:hover {
          color: darken($red, 15%);
        }
      }
      .disabled-action {
        color: lighten($red, 20%);

        &:hover {
          cursor: default;
        }
      }
    }
  }
  #recipient-select {
    margin: 10px 0 15px;
    > div {
      align-items: center;
    }

    .opted-out {
      font-style: italic;
    }
    #group-name-input {
      display: inline-block;
      width: 40%;
      padding: 0 1rem 0 0;
    }
    #clear-btn {
      margin-right: 10px;
    }
  }
  #csv-upload-container {
    .import-csv {
      width: 70%;
      margin-right: 10px;
    }
    #csv-key-select {
      width: 30%;
    }
  }
}
#msg-type-btns {
  margin-bottom: 30px;
  .btn {
    margin-right: 10px;
  }
  .btn:not(:first-child) {
    margin-left: 10px;
  }
}
#save-template {
  position: relative;
  right: 20%;
  width: 70%;
  display: flex;
  align-items: center;

  .custom-checkbox {
    display: inline-block;
    margin-right: 10px;
    width: 40%;
  }
}
#chat-window {
  width: 100%;
  max-height: 100%;

  border-style: none;

  .card {
    flex: 1;
    max-height: 100%;

    border-style: none;
    border-radius: 0;
  }

  .card-footer {
    height: 330px;
    padding: 24px;

    background: #455567;
    border-top: 1px solid #6a84a0;
  }

  #chat-input {
    position: relative;

    display: flex;
    flex-direction: row;
    flex-direction: column;
    height: 100%;

    background-color: #373c42;
    border-radius: 0.25rem;

    transition: background-color 0.2s ease, box-shadow 0.2s ease;

    #payment-link {
      display: inline-block;
      width: 22%;
      margin-left: 10px;
    }
    #language {
      width: 22%;
      margin-left: 20px;

      border-radius: 25px;
    }
    #chat-info {
      padding: 0.15em;
      border-radius: 100%;
      width: 2rem;
      height: 2rem;
      margin: 0 10px 0 0;
      float: right;
      &:hover {
        background: #6c757d;
        cursor: default;
      }

      svg {
        margin: 0;
      }
    }
    span {
      height: 100%;
    }
    #chat-input-text {
      flex: 1;
      width: calc(100% - 2rem);
      height: inherit;
      overflow: auto;

      color: white;
      font-size: 14px;
      line-height: 1.33;
      white-space: pre-wrap;
      word-wrap: break-word;

      background-color: transparent;
      border-style: none;
      outline: none;
      -webkit-font-smoothing: antialiased;

      &::placeholder {
        color: #ccc;
      }
    }

    #chat-input-text:empty::before {
      display: block;

      outline: none;
      cursor: text;
      filter: contrast(15%);

      content: attr(placeholder);
    }

    .actions {
      position: absolute;
      bottom: 0;
      left: 0;

      display: flex;
      justify-content: flex-end;
      width: 100%;
      padding: 0 1rem;

      .btn {
        color: $customer-detail-text-color;

        background-color: rgba(255, 255, 255, 0);
        border-color: transparent;

        &:hover {
          background-color: rgba(255, 255, 255, 0.3);
        }
      }
    }
  }

  .chat-input-disabled {
    background: transparent !important;
  }
}

.clear-chat-container {
  float: right;
  padding: 0.2em 1em 0 0;

  button {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.1em;

    background: #8193a9;
    border-radius: 100%;

    svg {
      width: 1.5rem;
      height: 1.5rem;
    }
  }
}
</style>
